body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

._7ahQImyV4dj0EpcNOjnwA{
  background-color: var(--viola)!important;
}

.lhyQmCtWOINviMz0WG_gr {
  background-color: #A875B0!important;
}

.multiSelectRuolo li{
    background-color: #A875B0!important ;
}
